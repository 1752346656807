'use client';

import * as React from 'react';
import { usePathname } from '@mentimeter/next-navigation';
import type { PromptT } from '@mentimeter/ragnar-ui';
import { ModalGeneric, Prompt } from '@mentimeter/ragnar-ui';
import { HotkeyHandler } from '@mentimeter/hotkeys';

interface ModalHookOptions {
  allowDismiss?: boolean;
  onClose?: () => void;
}

/**
 * @deprecated Use the new accessible modal components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-ui-modal--docs)
 */
export interface ModalContextT {
  content: React.ReactNode;
  open: (content: React.ReactNode, options?: ModalHookOptions) => void;
  close: () => void;
  show: boolean;
  allowDismiss: boolean;
}

interface PromptPropsT extends Omit<PromptT, 'actions'> {
  dismissLabel?: string;
  actions?: any[];
}

/**
 * @deprecated Use the new accessible modal components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-ui-modal--docs)
 */
export const ModalContext = React.createContext<ModalContextT | null>(null);
ModalContext.displayName = 'ModalContext';

/**
 * @deprecated Use the new accessible modal components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-ui-modal--docs)
 */
export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [content, setContent] = React.useState<React.ReactNode>(null);
  const [show, setShow] = React.useState<boolean>(false);
  const [onClose, setOnClose] = React.useState<(() => void) | null>(null);
  const [allowDismiss, setAllowDismiss] = React.useState<boolean>(true);

  const close = React.useCallback(() => {
    setShow(false);

    if (onClose) {
      onClose();

      // onClose callback is set when opening the modal,
      // so we need to clear it when we close the modal.
      setOnClose(null);
    }
  }, [onClose]);

  const open = React.useCallback(
    (contents: React.ReactNode, options?: ModalHookOptions) => {
      setShow((prev) => !prev);
      setContent(contents);
      setShow(true);
      if (options?.allowDismiss === false) {
        setAllowDismiss(options.allowDismiss);
      }

      if (options?.onClose)
        // The arrow function is needed to avoid the state setter interpretting
        // the onClose function as a dynamic state setter and directly executing it.
        setOnClose(() => options.onClose);
    },
    [],
  );

  return (
    <ModalContext.Provider value={{ close, open, content, show, allowDismiss }}>
      {children}
    </ModalContext.Provider>
  );
};

/**
 * @deprecated Use the new accessible modal components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-ui-modal--docs)
 */
export const useModal = (): ModalContextT => {
  const context = React.useContext(ModalContext);
  if (!context) throw Error(`useModal must be used inside of a ModalProvider`);

  return context;
};

/**
 * @deprecated Use the new accessible modal components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-ui-modal--docs)
 */
export const ModalPrompt = (props: PromptPropsT) => {
  return (
    <Prompt
      autoFocus={false}
      layer="far"
      dismissLabel="Close modal"
      hasDismiss={Boolean(props.onDismiss)}
      actionPattern="z"
      width="95vw"
      maxWidth="800px"
      my={3}
      mt={6}
      {...props}
    />
  );
};

/**
 * @deprecated Use the new accessible modal components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-ui-modal--docs)
 */
export const Modal = () => {
  const { content, close, show, allowDismiss = true } = useModal();
  const pathname = usePathname();
  React.useEffect(() => {
    close();
  }, [close, pathname]);

  const onDismiss = () => {
    if (allowDismiss) close();
  };

  return (
    <>
      {show && <HotkeyHandler keyCode="escape" onKeyUp={onDismiss} />}
      <ModalGeneric center={false} show={show} onDismiss={onDismiss}>
        {content}
      </ModalGeneric>
    </>
  );
};
